<template>
  <div>
    <button class="btn btn-primary" v-b-modal.create-task-intersection>
      <span style="text-transform: none">{{ $t("monitoringLocation.create_task") }}</span>
    </button>

    <b-modal class="createTaskDialog" size="lg" v-model="visible" id="create-task-intersection" centered hide-footer :title="this.$t('monitoringLocation.intersection_task_create_modal_title')">
      <div>
        <intersections-task-create
            ref="intersectionTaskCreate"
            @setSelectedTarget="setSelectedTarget"
            @setDistance="setDistance"
            @setTaskEndDate="setTaskEndDate"
            @setNote="setNote"
        />
        <footer class="d-grid gap-2 m-t-15">
          <b-button :disabled="!enableCreateButton || isLoading" variant="primary" class="btn" @click="createTask">{{ $t('ui.Ok') }}
            <span v-if="isLoading">
                            <b-spinner small></b-spinner>
                          </span>
          </b-button>
          <b-button type="button" variant="secondary"
                    @click="closeDialog" class="btn">{{ $t('user.cancel') }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>


</template>

<script>
import IntersectionsTaskCreate from "@/components/monitoringLocation/intersections-task-create";
import moment from "moment";
import {mapGetters} from "vuex";
export default {
  name: "intersectionTaskCreateModal",
  components: {IntersectionsTaskCreate},
  data() {
    return {
      visible: false,
      isLoading: false,
      selectedTarget: [],
      period: null,
      distance: 300,
      taskEndDate: '',
      note: ''
    }
  },
  computed: {
    enableCreateButton(){
       return this.selectedTarget.length === 2;
    },
  },
  methods: {
    setPeriod(val){
      this.period = val
    },
    showDialog() {
      this.visible = !this.visible;
    },
    closeDialog() {
      this.selectedTarget = [];
      this.visible = false;
    },
    setSelectedTarget(val) {
      this.selectedTarget = val;
    },
    setDistance(val){
      this.distance = val
    },
    setTaskEndDate(val){
      this.taskEndDate = val;
    },
    setNote(val){
      this.note = val;
    },

    createTask(){
        let payload = {
          target_ids: this.selectedTarget,
          distance: this.distance,
          expires_at:  parseInt(moment(this.taskEndDate).format("X")),
        }
        if (payload.expires_at === '' || !payload.expires_at){
          delete payload.expires_at
        }
        if (this.note !== ''){
          payload.note = this.note
        }
        this.isLoading = true;
        this.$store.dispatch('locationMonitoring/createCrossTrackingTask', payload).then(()=>{
          this.$refs['intersectionTaskCreate'].clearSettings();
          this.isLoading = false;
          this.$emit('updateList');
        })
    },
  }
}
</script>

<style scoped>

</style>