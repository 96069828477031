<template>
  <div>
    <button class="btn btn-primary" :disabled="mainButtonDisable" v-b-modal.create-task>
      <span style="text-transform: none">{{ $t("monitoringLocation.create_task") }}</span>
    </button>

    <b-modal class="createTaskDialog" size="lg" v-model="visible" id="create-task" centered hide-footer :title="this.$t('monitoringLocation.routers_create_title')">
      <div>

        <routes-task-create
          @setSelectedTarget="setSelectedTarget"
          @setPeriod="setPeriod"
        >
        </routes-task-create>

        <footer class="d-grid gap-2 m-t-15">
          <b-button :disabled="!enableCreateButton" variant="primary" class="btn" @click="createTask">{{ $t('geo.create') }}
            <span v-if="isLoading">
              <b-spinner small></b-spinner>
            </span>
          </b-button>
          <b-button type="button" variant="secondary"
                    @click="closeDialog" class="btn">{{ $t('user.cancel') }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RoutesTaskCreate from "@/components/monitoringLocation/routesTaskCreate";
import IntersectionsTaskCreate from "@/components/monitoringLocation/intersections-task-create";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "locationTaskCreate",
  components: {IntersectionsTaskCreate, RoutesTaskCreate},
  data() {
    return {
      visible: false,
      isLoading: false,
      selectedTarget: [],
      period: null,
      distance: 300,
      taskEndDate: ''
    }
  },
  watch: {
    visible(){
      if (!this.visible){
        this.selectedTarget = [];
      }
    },
  },
  computed: {
    ...mapGetters('monitoring', ['targets']),

    enableCreateButton(){
        if (this.selectedTarget.length > 0){
          return true
        }
      },
      mainButtonDisable() {
        let arr = this.targets.filter(target => !target.active)
        return arr.length === 0
      }
  },

  methods: {
    updateList(){
      this.$emit('updateList')
    },
    setPeriod(val){
      this.period = val
    },
    showDialog() {
      this.visible = !this.visible;
    },
    closeDialog() {
      // this.selectedTaskType = null;
      this.selectedTarget = [];
      this.visible = false;
    },
    setSelectedTarget(val) {
      this.selectedTarget = val;
    },
    createTask(){
        let payload = {
          type: 'location',
          id: this.selectedTarget[0],
          service_ids: ["5"]
        }
        if (this.period !== 0){
          payload.period = parseInt(this.period)
        }
        this.isLoading = true;
        this.$store.dispatch('locationMonitoring/createMonitoringTask', payload).then(()=>{
          this.updateList();
          this.isLoading = false;
        })
    },
  }
}
</script>

<style>
.form-select {
  width: 100%;
  border-radius: 2px;
  height: 35px;
}
</style>