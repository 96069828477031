<template>
    <div class="table-visited-events">
        <table class="table table-sm b-table table-hover">
            <thead>
                <tr>
                    <th>{{ $t('monitoringLocation.target') }}</th>
                    <th>{{ $t('monitoringLocation.address') }}</th>
                    <th class="maxWidth70">{{ $t('monitoringLocation.details') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="place in places">
                    <td class="checkbox-col border-checkbox-section">
                        <span class="border-checkbox-group border-checkbox-group-primary">
                            <input class="border-checkbox" type="checkbox" :id="`place_${place.id}`" :value="place"
                                v-model="selectedPlaces">
                            <label class="form-label border-checkbox-label" :for="`place_${place.id}`"><span
                                    v-if="getTargetAlias(place.target_id).length < 20">{{
                                    getTargetAlias(place.target_id) }}</span><span v-else>{{
                                    getTargetAlias(place.target_id) }}</span></label>
                        </span>
                    </td>
                    <td>{{ place.place.address }} <i class="feather icon-info"
                            v-tippy="{ placement : 'top',  arrow: true,  }"
                            :content="$t('monitoringLocation.address_info')"></i>
                    </td>
                    <visitedPlacesEventsDetail :place="place" :range="range"
                        :target="getTargetAlias(place.target_id)" />
                </tr>
                <tr v-if="!places.length">
                    <td colspan="6">{{ $t('monitoringLocation.emptyListEventsPlaces') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import visitedPlacesEvent from './visitedPlacesEvent.vue';
import { isUndefined } from 'underscore';
import moment from 'moment';
import visitedPlacesEventsDetail from './visitedPlacesEventsDetail.vue';

export default {
    name: 'visitedPlacesEvents',
    setup() {
        return {
            isUndefined,
            moment
        }
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        places: {
            type: Array,
            required: true
        },
        range: {
            type: Object,
            required: true
        }       
    },
    components: {
        visitedPlacesEvent,
        visitedPlacesEventsDetail
    },
    data() {
        return {
            selectedPlaces: []
        }
    },
    methods: {
        toHHMMSS(mins) {
            var sec_num = parseInt(mins, 10)*60;
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            const time = hours+':'+minutes+':'+seconds;
            return time === '00:00:00' ? '-' : time;
        },
        getTargetAlias(targetId) {
            return this.targets.find(target => target.target_id === targetId).alias;
        }
    },
    computed: {
        ...mapGetters('monitoring', ['targets']),
    },
    watch: {
        selectedPlaces(val) {
            this.$emit('input', val)
        }
    },
}
</script>

<style scoped>
    .table-visited-events {
        overflow-x: auto;
    }
    .maxWidth70 {
        max-width: 70px;
        width: 70px;
    }
</style>